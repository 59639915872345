import React from "react"
import { Link } from "gatsby"

const BlogCard = ({slug, title, date, image, excerpt}) => {
  return (
    <li key={slug}>
      <article
        className="post-list-item"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h2>
            <Link to={slug} itemProp="url">
              <span itemProp="headline">{title}</span>
            </Link>
          </h2>
          <small>{date}</small>
        </header>
        <section >
          <p
            dangerouslySetInnerHTML={{
              __html: excerpt,
            }}
            itemProp="description"
          />
        </section>
      </article>
    </li>
  )
}

export default BlogCard